<template>
    <credor-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros" :projeto="projeto" :isCessaoCredito="isCessaoCredito" :isRecuperacaoJudicial="isRecuperacaoJudicial"></credor-form>
</template>

<script>
import CredoresServices from './services';
import CredorForm from './CredorForm';

export default {
    components: {
        CredorForm,
    },

    props: {
        projeto: {
            type: Object,
        },

        isCessaoCredito: {
            type: Boolean,
            default: () => false,
        },

        isRecuperacaoJudicial: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Credores' });
        },

        inserir(credorNovo) {
            this.$store.dispatch('addRequest');
            let credorNovoDto = {
                projetoId: this.projeto.projetoId,
                tipoPessoa: credorNovo.tipoPessoa,
                cpfCnpj: credorNovo.cpfCnpj,
                identidade: credorNovo.identidade,
                identidadeOrgaoEmissor: credorNovo.identidadeOrgaoEmissor,
                identidadeUfEmissora: credorNovo.identidadeUfEmissora,
                classe: credorNovo.classe,
                nome: credorNovo.nome,
                situacaoCadastralId: credorNovo.situacaoCadastralId,
                naturezaId: credorNovo.naturezaId,
                historicoJuridico: credorNovo.historicoJuridico,
                valorNominal: credorNovo.valorNominal,
                valorCorrigido: credorNovo.valorCorrigido,
                precoTarget: credorNovo.precoTarget,
                precoMaximo: credorNovo.precoMaximo,
                valorQGC: credorNovo.valorQGC,
                valorPlano: credorNovo.valorPlano,
                contato: credorNovo.contato,
                telefone1: credorNovo.telefone1,
                telefone2: credorNovo.telefone2,
                email1: credorNovo.email1,
                email2: credorNovo.email2,
                endereco: credorNovo.endereco,
                enderecoAtual: credorNovo.enderecoAtual,
                statusContatoId: credorNovo.statusContatoId,
                statusInteresseId: credorNovo.statusInteresseId,
                statusNegociacaoId: credorNovo.statusNegociacaoId,
                statusPropostaId: credorNovo.statusPropostaId,
                statusCessaoId: credorNovo.statusCessaoId,
                statusProcuracaoId: credorNovo.statusProcuracaoId,
                origemId: credorNovo.origemId,
                responsavel: credorNovo.responsavel,
                observacoes: credorNovo.observacoes,
            };
            CredoresServices.inserirCredor(credorNovoDto).then((response) => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Credor',
                        detail: 'Credor inserido com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(credorId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Credores_Detalhar',
                params: {
                    id: credorId,
                },
            });
        },
    },
};
</script>
